export const authEndpoint = "https://accounts.spotify.com/authorize";

// Replace with your app's client ID, redirect URI and desired scopes
export const clientId = "4753f10680f943c5845424eda8abb1d3";
export const redirectUri = "https://beforeidieiwantto.web.app/redirect/";
export const scopes = [
    "user-top-read",
    "user-read-currently-playing",
    "user-read-playback-state",
    "user-library-read",
];
